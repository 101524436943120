
import { Vue, Component } from 'vue-property-decorator';

/**
 * ticket page for hubspot
 */
@Component
export default class Ticket extends Vue {
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          portalId: '8242216',
          formId: 'e70e6184-38d6-4d34-aaaa-5ffe5b1081eb',
          target: '#hubspotForm',
        });
      }
    });
  }
}
